import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Customers from "../components/customers"
import Testimonials from "../components/testimonials"
import FeatureCard from "../components/feature-card"
import { JobFeature } from "../components/job-components"
import QuestionAndAnswer from "../components/question-and-answer"
import ActionForm from "../components/action-form"
import MoreBlogPosts from "../components/more-blog-posts"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowRight,
  faCircleNodes,
  faCode,
  faImagePortrait,
  faMicrochip,
  faCircleInfo,
  faClock,
  faWrench,
  faServer,
  faDatabase,
} from "@fortawesome/free-solid-svg-icons"

const ProductEdge = ({ data }) => (
  <Layout>
    <SEO
      title="Real-Time Image and Video Anonymization for Edge Devices | Celantur"
      description="Image and Video Blurring SDK for Edge Solutions and Camera Systems."
    />

    <section className="container pt-3 pt-md-5 pb-3 pb-sm-3 bg-gradient">
      <div className="row align-items-center">
        <div className="col-12 cold-md-5 col-lg-6 order-md-2">
          <Img fluid={data.headerImage.childImageSharp.fluid} />
        </div>
        <div className="col-12 cold-md-7 col-lg-6 order-md-1">
          <h1 className="display-4 text-center text-md-left text-primary font-weight-bold">
            Real-Time Image and Video Anonymization for Edge Devices
          </h1>
          <p className="lead text-center text-md-left mb-5">
          Image and Video Blurring SDK for Edge Solutions and Camera Systems.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <ul className="list-unstyled">
            <li>
              <JobFeature>
                <strong>Compliance:</strong> Ensure compliance with data protection regulations like GDPR and CCPA.
              </JobFeature>
            </li>
            <li>
              <JobFeature>
                <strong>Real-Time:</strong> Accelerate data utilization through real-time anonymization, avoid
                post-processing bottlenecks.
              </JobFeature>
            </li>
            <li>
              <JobFeature>
                <strong>Cost- and Time Saving:</strong> Avoid expensive and time-consuming post-processing
                anonymization.
              </JobFeature>
            </li>
            <li>
              <JobFeature>
                <strong>Easy Integration:</strong> Use our SDK (C++, Python) in your workflows.
              </JobFeature>
            </li>
            <li>
              <JobFeature>
                <strong>Flexible Deployment:</strong> Runs on AI accelerators, virtual servers, edge devices, on-prem.
              </JobFeature>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section className="py-8 py-md-11 bg-black bg-dotted">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-7 text-center">
            <h2 className="display-3 fw-bold font-gradient">Solution Features</h2>
            <p className="lead text-muted mb-9">Powerful Anonymization, Optimized for Edge</p>
          </div>
        </div>
        <div className="row mt-n7">
          <FeatureCard headline="Blurring" icon={faImagePortrait} size="3x">
            Blur faces, persons, vehicles and license plates.
          </FeatureCard>
          <FeatureCard headline="Real-Time Processing" icon={faClock} size="3x">
            Anonymize images and videos instantly for immediate use.
          </FeatureCard>
          <FeatureCard headline="Flexible SDK" icon={faCode} size="3x">
            Easily integrate Celantur Edge into your C++ or Python applications.
          </FeatureCard>
          <FeatureCard headline="Model only" icon={faCircleNodes} size="3x">
            Work with a tailor-made ML Model only (ONNX, etc.).
          </FeatureCard>
          <FeatureCard headline="AI Accelerator Support" icon={faServer} size="3x">
            Deploy on Nvidia GPUs (including Jetson) and other AI Accelerators.
          </FeatureCard>
          <FeatureCard headline="GPU &amp; CPU Support" icon={faMicrochip} size="3x">
            Leverage GPU or CPU for optimal performance.
          </FeatureCard>
          <FeatureCard headline="Metadata" icon={faCircleInfo} size="3x">
            Extract bounding boxes and segmentation masks.
          </FeatureCard>
          <FeatureCard headline="I/O" icon={faDatabase} size="3x">
            video4linux, ffmpeg, jpg/png, any other interface.
          </FeatureCard>
          <FeatureCard headline="Utilities" icon={faWrench} size="3x">
            Profiling built in, Object Tracking for Videos, Tiling Options, etc.
          </FeatureCard>
        </div>
      </div>
    </section>

    <section className="pt-5 pb-5">
      <ActionForm
        source="edge"
        headline="Contact Us."
        placeholderInput="Tell us about your use case and requirements (optional)"
        labelSubmitButton="Send"
      />
    </section>

    <section className="pt-5 pb-5">
      <div className="container">
        <h2 className="font-weight-bold text-center">FAQ</h2>
        <QuestionAndAnswer question="How does Celantur Edge work?" isOpen={true}>
          Celantur Edge comes as an SDK tailor-made for your specific use case and system. It contains all the necessary
          libraries, interfaces and models to anonymize images and videos in real-time.
        </QuestionAndAnswer>
        <QuestionAndAnswer question="How much does it cost?" isOpen>
          Pricing is customized for each project and includes a core product license fee, as well as optional fees for
          services (time and material) and ongoing support. Please request a custom quote.
        </QuestionAndAnswer>
        <QuestionAndAnswer question="Can I just get a model to use in my existing inference infrastructure?" isOpen>
          Yes, we can provide machine learning models in several formats (ONNX, etc.).
        </QuestionAndAnswer>
        <QuestionAndAnswer question="How do I get early access?" isOpen>
          Please contact us with the form on the top of this page. Alternatively, send an email to hello@celantur.com.
        </QuestionAndAnswer>
        <QuestionAndAnswer question="Which types of object classes does it support?" isOpen>
          Faces and license plates. Support for persons and vehicles is coming soon.
        </QuestionAndAnswer>
      </div>
    </section>

    <section className="pt-5 pb-8">
      <Customers />
    </section>

    <Testimonials navvis riegl />

    <MoreBlogPosts caption="Latest Blog Posts about Edge AI" posts={data} />

    <section className="container my-5 text-center">
      {" "}
      {/* CTA */}
      <Link to="/contact/" role="button" className="btn btn-lg btn-success my-2 event-contact">
        Contact Us
        <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
      </Link>
    </section>
  </Layout>
)

export default ProductEdge

export const query = graphql`
  query EdgeBlogPosts {
    allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: ["Edge AI"] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            tags
            path
            teaser
            date
          }
        }
      }
    }
    headerImage: file(relativePath: { eq: "celantur-edge-visual.png" }) {
      ...imageProductPage
    }
  }
`
